@charset "utf-8";

/* FORMULÁŘE */

/* form parts */

form {
  margin: 0rem;
  padding: 0rem;
}

label {
  margin: 0rem 0rem 0rem 0rem;
  padding: 0rem 0rem 0rem 0rem;
  font-size: 1rem;
  line-height: var(--line-height-primary);
  display: block;
}

button,
[type=submit]{
  border: 0rem;
  cursor: pointer;
  font-family: inherit;
  background: none;
  font-size: 1rem;
  appearance: none;
  margin: 0rem;
  padding: 0rem;
}

@include SMALLSCREEN {
}

@include MEDIUMSCREEN {
}

@include WIDESCREEN {
}

/* form parts end */

/* input, select, textarea */

#{$form-element} {
  flex: none;
  width: 100%;
  min-width: 0;
  height: 2.875rem;
  line-height: normal;
  border-radius: 0.5rem;
  margin: 0rem;
  padding: 0.5em 3.25rem 0 1em;
  border: 1px solid var(--border-color-primary);
  background: url(../images/style/icons/error.svg) calc(100% - 1.125rem) -10rem / 1.5rem auto no-repeat var(--white);
  font-family: inherit;
  color: inherit;
  box-shadow: none;
  font-size: 1em;
  color: var(--color-primary);
  position: relative;

  &:not([value=""]):invalid {
    border-color: var(--red);
    background-position: calc(100% - 1.125rem) center;
  }

  &:focus {
    box-shadow: none;
  }

  &::placeholder {
    color: inherit;
    opacity: 0.6;
  }

  &::-ms-clear {
    display: none;
  }
}

select {
  appearance: none;
  padding-right: 2.5rem;
  background: url(../images/style/icons/selectbox.svg)calc(100% - 0.75rem) center / 0.875rem auto no-repeat;

  &::-ms-expand {
    display: none;
  }
}

textarea{
  width: 100%;
  min-height: 8rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  vertical-align: top;
}

@include SMALLSCREEN {

  #{$form-element} {
    height: 3.25rem;
  }
}

@include MEDIUMSCREEN {

  #{$form-element} {
    height: 3.563rem;
  }
}

@include WIDESCREEN {

  #{$form-element} {
  }
}

/* input, select, textarea end */

/* checkbox, radio */

#{$form-element-choice} {
  width: 1.563rem;
  height: 1.563rem;
  flex: none;
  border: 1px solid var(--border-color-primary);
  margin: 0rem;
  padding: 0rem;
  box-shadow: none;
  display: inline-block;
  vertical-align: top;
  appearance: none;
  background: center -10rem / 1.125rem auto no-repeat var(--white);

  &:checked {
    background-position: center center;
  }
}

[type="checkbox"] {
    background-image: url(../images/style/icons/checkbox.svg);
    border-radius: 0.188rem;
}

[type="radio"] {
    background-image: url(../images/style/icons/radio.svg);
    border-radius: 50%;
}

@include SMALLSCREEN {

  #{$form-element-choice} {
  }
}

@include MEDIUMSCREEN {

  #{$form-element-choice} {
  }
}

@include WIDESCREEN {

  #{$form-element-choice} {
  }
}

/* checkbox, radio end */

/* form list */

.form-list {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(--gap-element-2);

  &__block {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 0.5rem;

    &--gaps-tiny {
      gap: 0.313rem;
    }

    > * {
      margin-right: auto;
    }
  }
}

@include SMALLSCREEN {

  .form-list {
  }
}

@include MEDIUMSCREEN {

  .form-list {
  }
}

@include WIDESCREEN {

  .form-list {
  }
}

/* form list end */

/* form item */

.form-item {
  $self: &;
  width: 100%;
  position: relative;
  font-size: 1.125rem;
  font-family: var(--font-family-secondary);

  &__entry {
  }

  &__desc {
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 50%;
    padding: 0 1em;
    transform: translateY(-50%);
    transition: 0.15s linear;

    #{$self}__entry:not([value=""]):invalid  ~ & {
      color: var(--red);
    }

    #{$self}__entry:not([value=""]) ~ &,
    #{$self}__entry:focus ~ & {
      transform: translateY(-110%);
      font-size: 0.75em;
      padding-left: 1.4em;
    }
  }
}

@include SMALLSCREEN {

  .form-item {
    font-size: 1.15rem;
  }
}

@include MEDIUMSCREEN {

  .form-item {
    font-size: 1.25rem;
  }
}

@include WIDESCREEN {

  .form-item {
  }
}

/* form item end */

/* form item choice */

.form-item-choice {
  $self: &;
  opacity: 0;
  display: inline-flex;
  align-items: center;
  gap: 0.875rem;
  font-size: 1rem;
  animation: 0.25s linear 0s 1 revealChoice forwards;

  @keyframes revealChoice {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &:nth-of-type(1) {animation-delay: 0.2s;}
  &:nth-of-type(2) {animation-delay: 0.4s;}
  &:nth-of-type(3) {animation-delay: 0.6s;}
  &:nth-of-type(4) {animation-delay: 0.8s;}
  &:nth-of-type(5) {animation-delay: 1s;}
  &:nth-of-type(6) {animation-delay: 1.2s;}
  &:nth-of-type(7) {animation-delay: 1.4s;}
  &:nth-of-type(8) {animation-delay: 1.6s;}
  &:nth-of-type(9) {animation-delay: 1.8s;}
  &:nth-of-type(10) {animation-delay: 2s;}

  &.agreement {
    animation:none;
    opacity: 1;
    font-weight: bold;
  }

  &--checkbox {
  }
  &.checked{
    background-color: var(--blue-3);
  }

  &--radio {
  }

  &--box {
    padding: 0.75em 1em;
    border-radius: 1.875rem;
    background: var(--blue-4);
    min-height: 2.875rem;
    cursor: pointer;
  }

  &__entry {
    cursor: inherit;

    #{$self}--box & {
      background-color: inherit;
      border-color: #00303F33;
    }
  }

  &__desc {
    font-size: var(--font-size-primary);
    flex-grow: 1;
  }
}

@include SMALLSCREEN {

  .form-item-choice {
    font-size: 1.15rem;

    &--box {
      min-height: 3.25rem;
    }
  }
}

@include MEDIUMSCREEN {

  .form-item-choice {
    font-size: 1.25rem;

    &--box {
      min-height: 3.563rem;
    }
  }
}

@include WIDESCREEN {

  .form-item-choice {
  }
}

/* form item choide end */

/* form item range */

.form-item-range {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-h2);
  font-family: var(--font-family-secondary);
  gap: 0.75em;

  &__info {
    width: 100%;
    gap: 1.25rem;
    justify-content: space-between;
    display: flex;
  }

  &__label {
    font-weight: normal;
  }

  &__entry {
    appearance: none;
    width: 100%;
    height: 0.375rem;
    border-radius: 0.188rem;
    background: linear-gradient(var(--blue), var(--blue)) left center no-repeat var(--blue-4);
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 1em;

    &::-webkit-slider-thumb {
      appearance: none;
      width: 1.25em;
      height: 1.25em;
      font-size: 1em;
      border-radius: 50%;
      background: var(--blue);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      appearance: none;
      width: 1.25em;
      height: 1.25em;
      font-size: 1em;
      border-radius: 50%;
      background: var(--blue);
      cursor: pointer;
    }
  }
  
  &__desc {
    margin: 0;
    font-size: 1em;
    min-height: 5rem;
  }
  &__days-counter {
    display: inline-block;
    width: 2em;
    font-weight: bold;
    text-align: center;
  }
}

@include SMALLSCREEN {

  .form-item-range {
  }
}

@include MEDIUMSCREEN {

  .form-item-range {
  }
}

@include WIDESCREEN {

  .form-item-range {
  }
}

/* form item range end */