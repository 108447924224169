@charset "utf-8";

/* LAYOUT */

/* page wrapper */

.page-wrapper {
  height: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  gap: 0;
}

@include SMALLSCREEN {

  .page-wrapper {
  }
}

@include MEDIUMSCREEN {

  .page-wrapper {
    height: auto;
    display: block;
  }
}

@include WIDESCREEN {

  .page-wrapper {
  }
}

/* page wrapper end */

/* page header */

.page-header {
  position: sticky;
  z-index: 200;
  top: 0;
  padding: 0 var(--gap-page);
  background: var(--blue);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 3.875em;
}

@include SMALLSCREEN {
  
  .page-header {
  }
}

@include MEDIUMSCREEN {
  
  .page-header {
    background: none;
    background: linear-gradient(#00303FEE, #00303F00);
  }
}

@include WIDESCREEN {
  
  .page-header {
  }
}

/* page header generally end */

/* page content */

.page-content {
}

@include SMALLSCREEN {

  .page-content {
  }
}

@include MEDIUMSCREEN {

  .page-content {
    width: 46%;
    min-width: 40rem;
    margin-left: var(--gap-page);
    padding-bottom: var(--gap-page);
  }
}

@include WIDESCREEN {

  .page-content {
  }
}

/* page content end */

/* page footer */

.page-footer {
}

@include SMALLSCREEN {

  .page-footer {
  }
}

@include MEDIUMSCREEN {

  .page-footer {
  }
}

@include WIDESCREEN {

  .page-footer {
  }
}

/* page footer end */