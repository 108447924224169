@charset "utf-8";

/* KOMPONENTY */

/* page logo */

.page-logo {
  width: 11.875rem;
  flex: none;
  display: block;
  margin-right: auto;

  &__image {
    width: 100%;
  }
}

@include SMALLSCREEN {

  .page-logo {
  }
}

@include MEDIUMSCREEN {

  .page-logo {
  }
}

@include WIDESCREEN {

  .page-logo {

    &--header {
    }

    &--footer {
      margin-left: 4rem;
    }
  }

  .body--scrolled .page-logo {

    &--header {
      width: 4.125rem;
    }
  }
}

/* page logo end */

/* menu opener */

.menu-opener {
  $self: &;
  position: relative;;
  margin: 0 0 0 0;
  display: block;
  min-width: 1.938rem;
  height: 1.938rem;
  line-height: 1.938rem;

  &__text {
    color: var(--white);
    display: block;
    padding-right: 2.563rem;
    font-weight: bold;
    font-size: 1.125rem;
    font-family: var(--font-family-secondary);

    #{$self}.is-active & {
      display: none;
    }
  }

  &__content {
    position: absolute;
    display: block;
    width: 1.938rem;
    height: 100%;
    right: 0;
    top: 0;

    &:before,
    &:after {
      width: 1.5rem;
      height: 0.25rem;
      left: 0.25rem;
      display: block;
      content: '';
      background: var(--white);
      position: absolute;
      border-radius: 0.063rem;
      transition: 0.25s;
    }

    &--1 {

      &:before {
        top: 0.25rem;

        #{$self}.is-active & {
          opacity: 0;
        }
      }

      &:after {
        top: 1.375rem;

        #{$self}.is-active & {
          opacity: 0;
        }
      }
    }

    &--2 {

      &:before {
        top: 0.813rem;

        #{$self}.is-active & {
          transform: rotate(45deg);
        }
      }

      &:after {
        top: 0.813rem;

        #{$self}.is-active & {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@include SMALLSCREEN {

  .menu-opener {
  }
}

@include MEDIUMSCREEN {

  .menu-opener {
  }
}

@include WIDESCREEN {

  .menu-opener {
  }
}

/* menu opener */

/* message */

.message {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: none;
  min-height: 3rem;
  margin-bottom: 1rem;
  padding: 0.125rem 0.75rem 0.125rem 2.75em;
  text-align: left;
  font-size: var(--font-size-primary);
  border: 0.125rem solid transparent;
  background: 0.75em center / 1.625em auto no-repeat var(--white);
  opacity: 0;
  animation: message 0.5s 1.5s forwards;

  &--success {
    background-image: url(/images/style/icons/success.svg);
    border-color: var(--green);
    color: var(--green);
  }

  &--error {
    background-image: url(../images/style/icons/error.svg);
    border-color: var(--red);
    background-size: 1.313em auto;
    color: var(--red);
  }

  &--important {
    background-image: url(../images/style/icons/important.svg);
    border-color: var(--red);
    background-size: 0.313em auto;
    color: var(--red);
    background-position: 1.375em center;
  }

  &--notice {
    background-image: url(../images/style/icons/notice.svg);
    border-color: var(--blue);
    background-size: 0.313em auto;
    color: var(--blue);
    background-position: 1.375em center;
  }

  &--question {
    background-image: url(../images/style/icons/question.svg);
    border-color: var(--blue);
    background-size: 1em auto;
    color: var(--blue);
    background-position: 0.938em center;
  }

  & > * {
    color: inherit;
    font-size: 1em;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }

  & a {
    opacity: 0.7;
    color: inherit;
  }

  &:nth-child(1) {
    animation-delay: 0.5s;
  }

  &:nth-child(2) {
    animation-delay: 0.75s;
  }

  &:nth-child(3) {
    animation-delay: 1s;
  }

  &:nth-child(4) {
    animation-delay: 1.25s;
  }
}

@keyframes message {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include SMALLSCREEN {

  .message {
  }
}

@include MEDIUMSCREEN {

  .message {
  }
}

@include WIDESCREEN {

  .message {
  }
}

/* message end */

/* loader */

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 225;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white);
  font-size: 1rem;

  &__list {
    display: flex;
    gap: 0.438em;
  }

  &__item {
    display: block;
    height: 1.125em;
    width: 1.125em;
    border-radius: 50%;
    background-color: var(--blue);
    flex: none;
    animation: loader-item-bouncing 1000ms ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: 0ms
    }

    &:nth-child(2) {
      animation-delay: 333ms
    }

    &:nth-child(3) {
      animation-delay: 666ms
    }
  }
}

@keyframes loader-item-bouncing {

  0% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@include SMALLSCREEN {

  .loader {
  }
}

@include MEDIUMSCREEN {

  .loader {
    font-size: 1.3rem;
    position: static;
    background: none;
    height: 60vh;
  }
}

@include WIDESCREEN {

  .loader {
    font-size: 1.6rem;
  }
}

@include LARGESCREEN {

  .loader {
    font-size: 1.9rem;
  }
}

@include FULLSCREEN {

  .loader {
    font-size: 2.2rem;
  }
}

/* loader end */

/* intro */

.intro {
  color: var(--white);
  padding: var(--gap-element-2) var(--gap-page) 0 var(--gap-page);
  
  &__headline {
    font-size: var(--font-size-tertiary);
    max-width: 14.5em;
  }
  
  &__desc {
    font-size: var(--font-size-secondary);
    font-weight: 300;
    font-family: var(--font-family-secondary);
    line-height: var(--line-height-secondary);
    margin-bottom: calc(var(--gap-text) + 0.3em);
    max-width: 18em;
  }
}

@include SMALLSCREEN {

  .intro {
  }
}

@include MEDIUMSCREEN {

  .intro {
    padding-left: 0;
    padding-right: 0;
  }
}

@include WIDESCREEN {

  .intro {
    padding-top: var(--gap-element);
  }
}

/* intro end */

/* progress bar */

.progress-bar {
  height: 0.375rem;
  background: linear-gradient(var(--blue), var(--blue)) no-repeat var(--blue-4);

  &--steps {
  }
}

@include SMALLSCREEN {

  .progress-bar {
  }
}

@include MEDIUMSCREEN {

  .progress-bar {

    &--steps {
      display: none;
    }
  }
}

@include WIDESCREEN {

  .progress-bar {
  }
}

/* progress bar end */

/* step */

.step {
  height: 100%;
  overflow: hidden;
  background: var(--white);
  position: relative;

  &__form {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  &__body {
    overflow: auto;
    padding: var(--gap-element-2) var(--gap-element) calc(var(--gap-element) + 5.5rem) var(--gap-element);
  }
  &__examp{
    font-style: italic;
    opacity: 0.6;
    padding: calc(var(--gap-text)/2) 0 0 0;
    font-size: calc(var(--font-size-primary)*0.85);
  }

  &__top-block {
    margin-bottom: var(--gap-element-2);

    > *:last-child {
      margin-bottom: 0;
    }
  }
  
  &__footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 10;
    background: var(--white);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    justify-content: flex-end;
    padding: var(--gap-element);
  }
}

@include SMALLSCREEN {

  .step {
  }
}

@include MEDIUMSCREEN {

  .step {
    height: auto;
    border-radius: 0.625rem;
    overflow: visible;
    margin-bottom: calc(var(--gap-element-2) / 2);

    &:after {
      width: 0.833em;
      aspect-ratio: 1/1;
      position: absolute;
      right: 0;
      top: 50%;
      content: '';
      font-size: var(--font-size-h1);
      background: var(--white);
      transform: translate(50%, -50%) rotate(45deg);
    }

    &__form {
      display: block;
      height: auto;
    }

    &__body {
      overflow: visible;
      padding-top: var(--gap-element);
      padding-bottom: var(--gap-element);
    }
    
    &__footer {
      position: static;
      background: none;
    }

    &__buttons {
      gap: 2.125rem;
      padding-top: 0;
    }
  }
}

@include WIDESCREEN {

  .step {

    &__buttons {
      gap: 2.75rem;
    }
  }
}

/* step end */

/* thankyou page */

.thankyou-page {
  text-align: right;
}

@include SMALLSCREEN {

  .thankyou-page {
  }
}

@include MEDIUMSCREEN {

  .thankyou-page {
  }
}

@include WIDESCREEN {

  .thankyou-page {
  }
}

/* thankyou page end */

/* switcher */

.switcher {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;

  &--steps {
    display: none;
  }

  &__item {
    flex: none;
    width: 1.125rem;
    height: 1.125rem;
    padding: 0.125rem;

    &:after {
      width: 100%;
      height: 100%;
      padding: 0.125rem;
      border-radius: 50%;
      background: var(--white) content-box;
      display: block;
      border: 0.125rem solid #fff0;
      transition: 0.3s;
      content: '';
    }

    &.is-active {

      &:after {
        border-color: #fff;
        background: none;
      }
    }

    &.is-active ~ & {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

@include SMALLSCREEN {

  .switcher {
  }
}

@include MEDIUMSCREEN {

  .switcher {

    &--steps {
      display: flex;
    }
  }
}

@include WIDESCREEN {

  .switcher {
  }
}

/* switcher end */

/* badge wt100 */

.badge-wt100 {
  position: fixed;
  left: var(--gap-page);
  bottom: var(--gap-element-2);
  width: 7.875rem;
  display: none;
  
  .body-homepage & {
    display: block;
  }
}

@media screen and (max-width: 959px) and (max-height: 520px) {
  
  .badge-wt100 {
    
    .body-homepage & {
      display: none;
    }
  }
}

@include SMALLSCREEN {
  
  .badge-wt100 {
  }
}

@include MEDIUMSCREEN {
  
  .badge-wt100 {
    left: auto;
    right: var(--gap-page);
    bottom: var(--gap-page);
    width: 10.5rem;
    display: block;
  }
}

@include WIDESCREEN {
  
  .badge-wt100 {
    width: 13.5rem;
  }
}

/* badge wt100 end */