@charset "utf-8";

/* MENU */

/* MENU */

/* mobile menu */

.menu {
  display: none;
  font-family: var(--font-family-secondary);
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background: var(--blue);
  padding: 0 var(--gap-page) 1.875rem var(--gap-page);

  &.is-active {
    display: block;
  }


  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    &--1 {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 0.563rem 0.438rem;
    }
  }

  &__item {
    background: none;
    margin: 0;
    padding: 0;

    &.disabled {
        display: none;
      }
  }

  &__link {
    text-decoration: none;
    font-size: 1.125rem;
    line-height: var(--line-height-secondary);
    padding: 0.8em 3.55555%;
    display: block;
    color: var(--white);
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }

    &--1 {
      background: var(--blue-2);
      border-radius: 0.25em;
    }
  }
}

@include SMALLSCREEN {

  .menu {
  }
}

@include MEDIUMSCREEN {

  .menu {
    position: static;
    padding: 0 2rem 0 0;
    width: auto;
    background: none;

    &__list {

      &--1 {
        display: flex;
        flex-direction: row;
      }
    }

    &__item {

      &.lng {

        &:before {
          content: '|';
          color: var(--white);
          font-weight: bold;
          display: inline-block;
          margin: 0 0.75em 0 0.75em;
        }
      }
    }

    &__link {

      &--1 {
        color: var(--white);
        padding: 0.75em;
        font-weight: bold;
        border-radius: 0.44em;
        background: none;
        display: inline-block;

        &:hover {
          background-color: #fff4;
        }
      }
    }

  }
}

@include WIDESCREEN {

  .menu {
  }
}

/* menu end */