@charset "utf-8";

/* ZÁKLADNÍ NASTAVENÍ */

/* resolution settings */

@mixin TINYSCREEN { /* 480px */
  @media (min-width: 30rem) {
    @content
  }
}

@mixin SMALLSCREEN { /* 600px */
  @media (min-width: 37.5rem) {
    @content
  }
}

@mixin LIGHTSCREEN { /* 840px */
  @media (min-width: 52.5rem) {
    @content
  }
}

@mixin MEDIUMSCREEN { /* 960px */
  @media (min-width: 60rem) {
    @content
  }
}

@mixin WIDESCREEN { /* 1280px */
  @media (min-width: 80rem) {
    @content
  }
}

@mixin LARGESCREEN { /* 1440px */
  @media (min-width: 90rem) {
    @content
  }
}

@mixin FULLSCREEN { /* 1600px */
  @media (min-width: 100rem) {
    @content
  }
}

/* resolution settings end */

/* custom selectors variables */

$headline: "h1, h2, h3, h4, h5, h6, .headline";
$form-element: "[type=text], [type=password], [type=tel], [type=email], [type=url], [type=number], [type=date], select, textarea";
$form-element-choice: "[type=checkbox], [type=radio]";

/* custom selectors variables end */

/* gap variables */

:root {
  --gap-page: 1rem; /* set spacing for left and right page padding */
  --gap-element: 1rem; /* set spacing for elements */
  --gap-element-2: 1.875rem; /* set spacing for elements */
  --gap-text: 1rem; /* set spacing for text elements */

  @include SMALLSCREEN {
    --gap-page: 2rem;
  }

  @include LIGHTSCREEN {
    --gap-page: 3rem;
  }

  @include MEDIUMSCREEN {
    --gap-page: 4rem;
    --gap-element: 1.625rem;
    --gap-element-2: 2.188rem;
  }

  @include WIDESCREEN {
    --gap-element: 2.375rem;
    --gap-element-2: 2.188rem;
  }

  @include LARGESCREEN {
    --gap-element: 3.125rem;
    --gap-element-2: 2.5rem;
  }

  @include FULLSCREEN {
    --gap-element: 3.75rem;
  }
}

/* gap variables end */

/* font variables */

:root {
  --font-size-primary: 0.875rem;
  --font-size-secondary: 1.5rem;
  --font-size-tertiary: 1.75rem;
  --font-size-h1: 1.75rem;
  --font-size-h2: 1.125rem;
  --font-size-h3: unset;
  --font-size-h4: unset;
  --font-size-h5: unset;
  --font-size-h6: unset;
  --line-height-primary: 1.6;
  --line-height-secondary: 1.15;
  --font-family-primary: 'Open Sans', sans-serif;
  --font-family-secondary: Panton, 'Lucida Sans', 'Lucida Grande', sans-serif;

  @include SMALLSCREEN {
  }

  @include MEDIUMSCREEN {
    --font-size-primary: 1rem;
    --font-size-h2: 1.5rem;
  }

  @include WIDESCREEN {
    --font-size-primary: 1.125rem;
    --font-size-secondary: 1.875rem;
    --font-size-tertiary: 2.5rem;
    --font-size-h1: var(--font-size-secondary);
  }

  @include LARGESCREEN {
    --font-size-secondary: 2.625rem;
    --font-size-tertiary: 3.5rem;
  }

  @include FULLSCREEN {
    --font-size-secondary: 3rem;
    --font-size-tertiary: 4.5rem;
  }
}

/* font variables end */

/* color variables */

:root {
  --color-primary: #00303F;
  --red: #D04F55;
  --green: #70b624;
  --blue: #0290BF;
  --blue-2: #006789;
  --blue-3: #BDD8E5;
  --blue-4: #E2EAEF;
  --white: #FFFFFF;
  --border-color-primary: var(--blue-3);
}

/* color variables end */

/* other variables */

:root {
}

/* other variables end */