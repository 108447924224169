@charset "utf-8";

/* BUTTONY */

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  font-size: 1.125rem;
  padding: 0.75rem 1.3em;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
  line-height: 1.3;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 400;
  border-radius: 1.3em;
  background: no-repeat;
  animation: 5s infinite linear paused;
  font-family: var(--font-family-secondary);
  margin: 0;
  user-select: none;

  &--primary {
    color: var(--white);
    background: linear-gradient(150deg, var(--blue) 0%, #33BBCF 50%) left center / 200% auto no-repeat;

    &:hover {
      background-position: right center;
      text-decoration: none;
    }
  }

  &--secondary {
    padding: 0;
    background: none;
    color: var(--blue-2);

    &:hover {
      text-decoration: underline;
    }
  }

  &--secondary-icon {
    gap: 0.875rem;

    &:before {
      content: "";
      flex: none;
      aspect-ratio: 1/1;
      width: 1em;
      background: center center / contain no-repeat;
    }
  }

  &--secondary-icon-reload {

    &:before {
      order: 2;
      font-size: 1rem;
      width: 1.313rem;
      background-image: url(../images/style/icons/reload.svg);
    }
  }

  &--step-reload {
    width: 1.313rem;
    overflow: hidden;
    justify-content: flex-end;
    margin-right: auto;
  }

  &--step-submit {

    form:invalid &,
    form.is-invalid & {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

@include SMALLSCREEN {
  
  .button {
    font-size: 1.25rem;

    &--step-reload {
      width: auto;
      overflow: visible;
      justify-content: center;
    }
  }
}

@include MEDIUMSCREEN {
  
  .button {
    font-size: 1.5rem;
  }
}

@include WIDESCREEN {
  
  .button {
  }
}