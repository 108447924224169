@charset "utf-8";

/* ZÁKLADNÍ CSS */

*, *:after, *:before {
  box-sizing: border-box;
  outline: none;
}

html {
  height: 100%;
}

body {
  min-width: 22.5rem;
  height: 100%;
  padding: 0rem;
  margin: 0rem;
  font-size: 100%;
  font-family: var(--font-family-primary);
  color: var(--color-primary);
  background: var(--white);
  line-height: var(--line-height-primary);
  -webkit-font-smoothing: antialiased;
}

.root {
  height: 100%;
}

a, .link {
  color: var(--color-text-primary);
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s;

  &:hover {
    text-decoration: none;
  }
}

b, strong {
  font-weight: bold;
}

img, svg {
  display: block;
  max-width: 100%;
  height: auto;

  #{"a"} & {
    border: 0rem;
  }
}

#{$headline} {
  margin: 0rem 0rem var(--gap-text) 0rem;
  line-height: var(--line-height-secondary);
  display: block;
  font-family: var(--font-family-secondary);
  font-weight: bold;
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: 400;
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

h6 {
  font-size: var(--font-size-h6);
}

p {
  font-size: var(--font-size-primary);
  margin: 0rem 0rem var(--gap-text) 0rem;
}

table {
  margin: 0rem 0rem var(--gap-text) 0rem;
  border-spacing: 0rem;
  border-collapse: collapse;
  border: 0rem;
}

th, td {
  padding: 0.75rem 0.5rem;
  text-align: left;
  font-size: var(--font-size-primary);
  border: 0rem solid var(--border-color-primary);
  border-top-width: 1px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  #{"tr:last-of-type"}  & {
    border-bottom-width: 1px;
  }
}

th {
}

td {
}

ul, ol {
  font-size: var(--font-size-primary);
  margin: 0rem 0rem var(--gap-text) 0rem;
  padding: 0rem;
  list-style-position: inside;

  #{"li"}  & {
    font-size: 1em;
    margin-top: calc(var(--gap-text) / 2);
    margin-bottom: 0rem;
  }
}

ul {
  list-style: none;
}

ol {
}

li {
  margin: 0rem 0rem calc(var(--gap-text) / 2) 0rem;
  padding: 0rem 0rem 0rem 0rem;

  #{"li"} & {
    padding-left: 1.125rem;
  }

  #{"ul"} & {
    background: linear-gradient(var(--color-text-primary), var(--color-text-primary)) 0rem 0.5em / 0.375rem 0.375rem no-repeat;
    padding-left: 1.125rem;
  }
}

iframe {
  border: 0rem;
  display: block;
  width: 100%;
  margin: 0rem;
  padding: 0rem;
}

[disabled],
:disabled,
.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}

@include SMALLSCREEN {
}

@include MEDIUMSCREEN {

  html {
    height: auto;
  }

  body {
    height: auto;
  }

  .root {
    height: auto;
  }
}

@include WIDESCREEN {
}